
import { Component, Prop } from 'vue-property-decorator';
import SimpleGeneralForm from '@/components/article/form/SimpleGeneralForm.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import EditArticleLoader from '@/components/article/EditArticleLoader.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import { Article } from '@/interfaces/models/Article';
import EditSimpleArticleLoader from '@/components/article/EditSimpleArticleLoader.vue';
import PriceByTypeForm from '@/components/article/form/PriceByTypeForm.vue';
import AvailabilityForm from '@/components/article/form/AvailabilityForm.vue';
import { CustomerGroup } from '@/enums/CustomerGroup';

@Component({
  components: {
    AvailabilityForm,
    PriceByTypeForm,
    EditSimpleArticleLoader,
    EditArticleLoader,
    VWrapper,
    SimpleGeneralForm,
  },
})
export default class EditSimpleArticleModal extends mixins(Editable, StackedForm, Notification, EditArticleMixin) {
  public $refs!: {
    general: InstanceType<typeof SimpleGeneralForm> & { getData: () => any };
    price: InstanceType<typeof PriceByTypeForm> & { getData: () => any };
    availability: InstanceType<typeof AvailabilityForm> & { getData: () => any };
  };

  @Prop({ type: String }) public articleId!: string;
  @Prop({ type: String }) public categoryId!: string;

  public async mounted() {
    this.$startLoading('venue');

    if (this.editing) {
      await this.show({ id: this.articleId, category: this.categoryId });
    }

    this.$stopAllLoading();
  }

  get article() {
    if (this.active && this.editing) {
      return { ...this.active, category: this.categoryId };
    }

    return null;
  }

  get shouldDisablePriceByType() {
    return this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu;
  }

  get shouldDisableAvailability() {
    return this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu;
  }

  get shouldDisablePreorderInside() {
    return (
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.Subway ||
        this.venue.customerGroup === CustomerGroup.SubwayFr ||
        this.venue.customerGroup === CustomerGroup.SubwayLu)
    );
  }

  get shouldDisablePreorderDelivery() {
    return (
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu)
    );
  }

  protected async save() {
    this.$startLoading('save_article');
    Promise.all(await this.validate()).then(async (res: any) => {
      if (this.isValid(res)) {
        const data: Partial<Article> = {
          ...this.$refs.general.getData(),
        };
        if (this.$refs.price) {
          data.priceByType = { ...this.$refs.price.getData() };
        }
        if (this.$refs.availability) {
          data.availability = { ...this.$refs.availability.getData() };
        }
        data.id = this.active._id;
        data.category = this.categoryId;
        await this.update(data);
        this.$stopLoading('save_article');
        this.$emit('cancel');
      } else {
        this.notifyError('notification.form');
        this.$stopLoading('save_article');
      }
    });
  }

  protected cancel() {
    this.$emit('cancel');
  }
}
