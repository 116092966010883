
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import { Category } from '@/interfaces/models/Category';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import VenueApiService from '@/api/http/VenueApiService';
import _ from 'lodash';

const api = new VenueApiService();
const app = namespace('app');

@Component({
  components: { VFormBuilder },
})
export default class GastrofixDialog extends mixins(StackedForm, Notification) {
  @Prop({ type: String, required: true }) public venueId!: string;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & {
      setData: (form: any) => void;
    };
  };
  public show: boolean = false;
  get items() {
    return [
      {
        name: 'gastrofixBusinessUnit',
        type: InputType.Text,
        label: 'article.gastrofix.gastrofixBusinessUnit',
        rules: 'required',
      },
      {
        name: 'gastrofixApiKey',
        type: InputType.Text,
        label: 'article.gastrofix.gastrofixApiKey',
        rules: 'required',
      },
      {
        name: 'importLanguage',
        type: InputType.Select,
        label: 'article.gastrofix.gastrofixImportLanguage',
        rules: 'required',
        items: ['de', 'en', 'fr'],
        default: 'de',
      },
    ];
  }

  public setShowModal(showModal: boolean = true) {
    this.show = showModal;
    if (!showModal) {
      this.$refs.form.reset();
      this.$refs.form.clearErrors();
    }
  }

  public async save() {
    if (this.$refs.form.validate()) {
      this.$startLoading('import_foodcard');
      const data = _.cloneDeep(this.$refs.form.getData());
      await api.importFoodcardGastrofix(this.venueId, data);
      this.$stopLoading('import_foodcard');
      this.setShowModal(false);
      this.$emit('updateFoodcard');
      this.notifySuccess('article.gastrofix.success');
    }
  }
}
