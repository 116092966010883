
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VHeadlineAction from '../../shared/VHeadlineAction.vue';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import uuid from 'uuid/v4';
import { OptionArticle } from '@/interfaces/models/OptionArticle';

interface Default {
  group: string;
  articles: string[];
  key: string;
  mapped: Mapped[];
}

interface Mapped {
  article: string;
  group: string;
  printerSortOrder: number;
  quantity: number;
}

@Component({
  components: { VHeadlineAction },
})
export default class ArticleDefaultsForm extends Vue {
  @Prop({ type: String, required: true }) public label!: string;
  @Prop({ type: Array, default: [] }) public selectedGroups!: OptionGroup[];
  @Prop({ type: Array, default: [] }) public options!: OptionGroup[];
  @Prop({ type: Array, required: true }) public items!: Default[];
  @Prop({ type: String, default: '' }) public actionText!: string;

  public defaults: Default[] = [];

  public getPossibleOptions(key: string) {
    if (this.defaults.length === 0) {
      return this.selectedGroups;
    }
    const items: OptionGroup[] = [];

    for (const selectedGroup of this.selectedGroups) {
      let isValid: boolean = true;
      for (const defaults of this.defaults) {
        // @ts-ignore
        if (selectedGroup === defaults.group && key !== defaults.key) {
          isValid = false;
          break;
        }
      }

      if (isValid) {
        // @ts-ignore
        items.push(this.options.filter((item: OptionGroup) => item._id === selectedGroup).pop()!);
      }
    }

    return items;
  }

  public addDefault() {
    this.defaults.push({
      group: '',
      key: uuid(),
      articles: [],
      mapped: [],
    });
  }

  public mounted(): void {
    this.initDefaults();
  }

  public normalizedMappedOptions(): Mapped[] {
    const defaults: Mapped[] = [];
    for (const item of this.defaults) {
      defaults.push(...item.mapped);
    }

    return defaults;
  }

  public getData() {
    return this.normalizedMappedOptions();
  }

  @Watch('defaults', { deep: true })
  public onDefaultsChange() {
    for (const item of this.defaults) {
      for (const article of item.articles) {
        const existing: Mapped = item.mapped.find((mapped: Mapped) => mapped.article === article)!;
        if (!existing) {
          item.mapped.push({
            article,
            group: item.group,
            printerSortOrder: 1,
            quantity: 1,
          });
        }
      }
      for (let i: number = 0; i < item.mapped.length; i += 1) {
        const mapped: Mapped = item.mapped[i];
        const removedItemIndex: number = item.articles.indexOf(mapped.article);
        if (removedItemIndex === -1) {
          item.mapped.splice(i, 1);
        }
      }
    }
  }

  public initDefaults() {
    if (this.items) {
      for (const item of this.items) {
        const addedItem: Default = this.defaults.find((d: Default) => d.group === item.group)!;
        if (!addedItem) {
          this.defaults.push({
            key: uuid(),
            group: item.group,
            // @ts-ignore
            articles: [item.article],
            // @ts-ignore
            mapped: [{ ...item }],
          });
        } else {
          // @ts-ignore
          addedItem.articles.push(item.article);
          // @ts-ignore
          addedItem.mapped.push({ ...item });
        }
      }
    }
  }

  public removeDefault(index: number) {
    this.defaults.splice(index, 1);
  }

  public getArticles(group: string) {
    const item: OptionGroup = this.options.find((item: OptionGroup) => item._id === group)!;
    return item ? item.articles : [];
  }

  public getArticle(group: string, article: string) {
    const item: OptionGroup = this.options.find((item: OptionGroup) => item._id === group)!;
    if (item) {
      return item.articles.find((art: OptionArticle) => art._id === article);
    }

    return null;
  }
}
