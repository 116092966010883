
import { Component } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { ValidationObserver } from 'vee-validate';
import { Company } from '@/interfaces/models/Company';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import { Visibility } from '@/interfaces/models/Visibility';
import { VisibilityType } from '@/enums/VisibilityType';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import { Article } from '@/interfaces/models/Article';

const venue = namespace('venue');
const company = namespace('company');

@Component({
  components: { VFormBuilder, ValidationObserver },
})
export default class VisibilityForm extends mixins(StackedForm) {
  @venue.State('active') public venue!: Venue;
  @company.State('items') companies!: Company[];

  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  public visibilityList: Visibility[] = this.serializedvisibilityList();

  public serializedvisibilityList() {
    return [...((this.initialValues as Article)?.visibilites || [])].map((item: Visibility) => {
      return {
        visible: item.visible,
        type: item.type,
        value: item.value,
      };
    });
  }

  public getData() {
    return this.visibilityList;
  }

  public filteredCompanies(item: Visibility): Company[] {
    return this.companies.filter((company: Company) => {
      const isMatchingId = item.value === company._id;
      const isNotInVisibilityList = !this.visibilityList.some((v: Visibility) => v.value === company._id);

      return isMatchingId || isNotInVisibilityList;
    });
  }

  get visibilityType() {
    return [{ value: VisibilityType.DND_COMPANY, text: this.$t('article.form.dndCompany') }];
  }

  public addVisibility() {
    this.visibilityList.push({
      visible: false,
      type: VisibilityType.DND_COMPANY,
      value: '',
    });
  }

  public removePrice(item: Visibility) {
    this.visibilityList.splice(this.visibilityList.indexOf(item), 1);
  }

  public validate() {
    return this.$refs.observer.validate();
  }
}
