
import { Component } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { Article } from '@/interfaces/models/Article';

@Component({
  components: { VFormBuilder },
})
export default class GeneralForm extends mixins(StackedForm) {
  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get items() {
    return [
      {
        name: 'price',
        type: InputType.Price,
        label: 'article.form.price',
        rules: 'required',
        disabled: this.isDisabledCallback,
      },
      { name: 'priceIsLocked', type: InputType.Hidden, label: '', default: false },
      { name: 'isNoticeable', type: InputType.Checkbox, label: 'article.form.isNoticeable', default: true },
    ];
  }

  public isDisabledCallback(form: { priceIsLocked: boolean }) {
    return form.priceIsLocked || this.hasDisabledStandardPriceLockByType();
  }

  public hasDisabledStandardPriceLockByType() {
    const article: Article = this.initialValues as Article;

    return !!(article && article.priceLockByType && article.priceLockByType.standard);
  }
}
