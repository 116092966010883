
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import StackedForm from '@/mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Article } from '@/interfaces/models/Article';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { cloneDeep } from '@/util/helper';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { SelectItem } from '@/interfaces/components/SelectItem';
import VHeadlineAction from '@/components/shared/VHeadlineAction.vue';
import ArticleDefaultsForm from '@/components/article/form/ArticleDefaultsForm.vue';
import EditOptionDialog from '@/components/article/EditOptionDialog.vue';
import { namespace } from 'vuex-class';

const foodcard = namespace('foodcard');

interface Group {
  group: string | null;
  articles: string[];
}

interface Mapped {
  article: string;
  group: string;
  printerSortOrder: number;
  quantity: number;
}

interface Default {
  group: string;
  articles: string[];
  key: string;
  mapped: Mapped[];
}

interface Dependency {
  group: string | null;
  dependencies: DependencyItem[];
}

interface DependencyItem {
  articles?: any;
  groupArticles: string[];
}

@Component({
  components: { ArticleDefaultsForm, VHeadlineAction, VFormBuilder, EditOptionDialog },
})
export default class OptionForm extends mixins(StackedForm) {
  @foodcard.Action('concatPaginatedOptions') public getConcatPaginatedOptions: any;
  @foodcard.Action('filterOptionsByIds') public filterOptionsByIds: any;
  @foodcard.Action('resetOptions') public resetOptions: any;
  @foodcard.State((state) => state.optionPagination.total) public total!: number;
  @foodcard.State((state) => state.optionsByArticle) public optionsByArticle!: any;

  @foodcard.Action('setOptionArticleFilter') public setOptionArticleFilter!: any;

  @Prop({ type: Array, required: true }) public options!: OptionGroup[];
  @Prop({ type: Array, required: true }) public articles!: Article[];

  public mappedGroups: Group[] = [];

  public groupDependencies: Dependency[] = [];
  public groupReverseDependencies: Dependency[] = [];

  public defaults: Default[] = [];

  public $refs!: {
    defaults: InstanceType<typeof ArticleDefaultsForm> & { getData: () => Default[]; defaults: Default[] };
    recommendations: InstanceType<typeof ArticleDefaultsForm> & { getData: () => Default[]; defaults: Default[] };
    editOptionDialog: InstanceType<typeof EditOptionDialog> & { getData: () => any };
  };

  protected page: number = 1;

  protected timeout: any;

  public getGroup(id: string): OptionGroup | undefined {
    return this.options.find((og: OptionGroup) => og._id === id);
  }

  public sortGroup(optionGroups: string[] = []) {
    const sorted = [];

    for (const og of optionGroups) {
      const a = this.getGroup(og);
      if (a) {
        sorted.push(a);
      }
    }

    return sorted.sort((a, b) => a.sortOrder - b.sortOrder);
  }

  get items() {
    return [
      {
        name: 'groups',
        type: InputType.Autocomplete,
        label: 'article.form.options',
        items: this.optionItems,
        change: this.onGroupsChange,
        multiple: true,
        onSearch: this.onChangeSearch,
        loadMore: this.loadMoreOptions,
      },
      { name: 'isMappedArticle', type: InputType.Checkbox, label: 'article.form.mappedArticle' },
      {
        name: 'main',
        type: InputType.Autocomplete,
        label: 'article.form.main',
        items: this.articleItems,
        disabled: (form: { isMappedArticle: boolean }) => !form.isMappedArticle,
      },
    ];
  }

  public getOptionArticles(form: { groups: string[] }) {
    const articles: SelectItem[] = [];
    const groups: OptionGroup[] = this.options.filter((o: OptionGroup) => form.groups.indexOf(o._id) > -1);
    for (const group of groups) {
      for (const art of group.articles) {
        articles.push({
          value: art._id,
          text: this.$options.filters!.localized(art.name),
        });
      }
    }

    return articles;
  }

  get optionItems() {
    return this.options.map((o: OptionGroup) => {
      return {
        value: o._id,
        text: this.$options.filters!.localized(o.name),
      };
    });
  }

  public loadMoreOptions() {
    if (this.options.length < this.total || this.total === 0) {
      this.getConcatPaginatedOptions(this.page);
      this.page += 1;
    }
  }

  public onChangeSearch(searchTerm: null | string) {
    clearTimeout(this.timeout);
    const self = this;

    this.timeout = setTimeout(() => {
      // return null if type event are focus, blur etc.
      if (searchTerm !== null) {
        self.page = 1;
        this.$emit('search', { page: this.page, limit: 20, search: searchTerm });
      }
    }, 500);
  }

  get articleItems() {
    return this.articles.map((a: Article) => {
      return {
        value: a._id,
        text: this.$options.filters!.localized(a.name),
      };
    });
  }

  public mounted() {
    // @ts-ignore
    if (this.initialValues) {
      // @ts-ignore
      if (this.initialValues.mappedGroups) {
        // @ts-ignore
        this.mappedGroups.push(...cloneDeep(this.initialValues.mappedGroups));
      }
      // @ts-ignore
      if (this.initialValues.groupDependencies) {
        // @ts-ignore
        this.groupDependencies.push(...cloneDeep(this.initialValues.groupDependencies));
      }

      // @ts-ignore
      if (this.initialValues.reverseGroupDependencies) {
        // @ts-ignore
        this.groupReverseDependencies.push(...cloneDeep(this.initialValues.reverseGroupDependencies));
      }
    }
  }

  public getMainArticle(form: any) {
    if (form.main) {
      const main: Article | undefined = this.articles.find((art: Article) => art._id === form.main);

      if (main) {
        const groups: OptionGroup[] = [];

        for (const g of main.groups) {
          const group: OptionGroup | undefined = this.getGroup(g as unknown as string);
          if (group) {
            groups.push(group);
          }
        }

        return { ...main, groups };
      }
    }

    return null;
  }

  public onGroupsChange(value: string[]) {
    this.filterOptionsByIds(value);
    this.$refs.defaults.defaults = this.$refs.defaults.defaults.filter((d: Default) => value.indexOf(d.group) !== -1);
    this.mappedGroups = this.mappedGroups.filter((g: Group) => value.indexOf(g.group!) !== -1);
    this.groupDependencies = this.groupDependencies.filter((gd: Dependency) => value.indexOf(gd.group!) !== -1);
    this.groupReverseDependencies = this.groupReverseDependencies.filter(
      (gd: Dependency) => value.indexOf(gd.group!) !== -1,
    );

    const articles: string[] = this.getOptionArticles({ groups: value }).map((i: SelectItem) => i.value) as string[];

    for (const groupDep of this.groupDependencies) {
      for (const dep of groupDep.dependencies) {
        const toRemove: number[] = [];
        for (const art of dep.groupArticles) {
          const index: number = articles.indexOf(art);
          if (index === -1) {
            toRemove.push(index);
          }
        }
        for (const r of toRemove) {
          dep.groupArticles.splice(r, 1);
        }
      }
    }

    for (const groupDep of this.groupReverseDependencies) {
      for (const dep of groupDep.dependencies) {
        const toRemove: number[] = [];
        for (const art of dep.groupArticles) {
          const index: number = articles.indexOf(art);
          if (index === -1) {
            toRemove.push(index);
          }
        }
        for (const r of toRemove) {
          dep.groupArticles.splice(r, 1);
        }
      }
    }
  }

  public getRulesForDep(dep: DependencyItem, i: number, execeptJ: number): string {
    const rules: string[] = ['required'];
    const fields: string[] = [];
    for (let index: number = 0; index < dep.groupArticles.length; index += 1) {
      if (index !== execeptJ) {
        fields.push(`@articles-${i}-${index}`);
      }
    }
    if (fields.length > 0) {
      rules.push(`unique_dependency:${fields.join(',')}`);
    }

    return rules.join('|');
  }

  public getGroupArticles(form: any, group: string) {
    const main: Article = this.getMainArticle(form)!;
    if (main) {
      const optionGroup: OptionGroup = (main.groups as OptionGroup[]).find((g: OptionGroup) => g._id === group)!;
      if (optionGroup) {
        return optionGroup.articles;
      }
    }

    return [];
  }

  public splitDependenciesArticle(indexDep: number, indexDepArticle: number) {
    const handleGroup: Dependency = this.groupDependencies[indexDep];
    const handleDepArticle: DependencyItem = handleGroup.dependencies[indexDepArticle];
    const splitted = handleDepArticle.groupArticles.reduce((acc: any, currentValue: any) => {
      acc.push({ articles: handleDepArticle.articles, groupArticles: [currentValue] });
      return acc;
    }, []);
    this.removeDependency(this.groupDependencies[indexDep], handleDepArticle);
    this.groupDependencies[indexDep].dependencies.push(...splitted);
  }

  public removeGroup(group: Group) {
    const index: number = this.mappedGroups.indexOf(group);
    if (index > -1) {
      this.mappedGroups.splice(index, 1);
    }
  }

  public removeGroupDependency(dep: Dependency) {
    const index: number = this.groupDependencies.indexOf(dep);
    if (index > -1) {
      this.groupDependencies.splice(index, 1);
    }
  }

  public removeGroupReverseDependency(dep: Dependency) {
    const index: number = this.groupReverseDependencies.indexOf(dep);
    if (index > -1) {
      this.groupReverseDependencies.splice(index, 1);
    }
  }

  public removeDependency(group: Dependency, dep: DependencyItem) {
    const index: number = group.dependencies.indexOf(dep);

    if (index > -1) {
      group.dependencies.splice(index, 1);
    }
  }

  public addGroup() {
    this.mappedGroups.push({
      group: null,
      articles: [],
    });
  }

  public addGroupDependency() {
    this.groupDependencies.push({
      group: null,
      dependencies: [],
    });
  }

  public addGroupReverseDependency() {
    this.groupReverseDependencies.push({
      group: null,
      dependencies: [],
    });
  }

  public addDependency(group: Dependency) {
    group.dependencies.push({
      groupArticles: [],
    });
  }

  public getPossibleDependencyGroups(form: { groups: string[] }, ignoreGroup: string) {
    if (!form.groups || form.groups.length === 0) {
      return [];
    }
    const items: SelectItem[] = [];
    for (const group of form.groups) {
      if (!this.groupDependencies.find((dep: Dependency) => dep.group === group && group !== ignoreGroup)) {
        const selectItem: SelectItem = this.optionItems.find((o: SelectItem) => o.value === group)!;
        items.push(selectItem);
      }
    }

    return items;
  }

  public getArticle(group: string, article: string) {
    const item: OptionGroup = this.options.find((item: OptionGroup) => item._id === group)!;
    if (item) {
      return item.articles.find((art: OptionArticle) => art._id === article);
    }

    return null;
  }

  public getData() {
    return {
      // @ts-ignore
      ...this.$refs.form.form,
      groupDependencies: this.groupDependencies,
      reverseGroupDependencies: this.groupReverseDependencies,
      mappedGroups: this.mappedGroups,
      defaults: this.$refs.defaults.getData(),
      recommendations: this.$refs.recommendations.getData(),
    };
  }

  public handleOpenModal(id: string) {
    this.setOptionArticleFilter({});

    // Set option to be edited
    // this.$refs.editOptionDialog.show({id})
    this.$refs.editOptionDialog.getOptionDetails(id);
    // Open option modal
    this.$refs.editOptionDialog.setShowModal(true);
  }
}
