
import { Component } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import VFormBuilder from '../../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';

@Component({
  components: { VFormBuilder },
})
export default class ImageForm extends mixins(StackedForm) {
  get items() {
    return [
      { name: 'assets', type: InputType.File, label: 'article.form.image', default: null, accept: 'image/*' },
      { name: 'banner', type: InputType.File, label: 'article.form.banner', default: null, accept: 'image/*' },
      { name: 'icon', type: InputType.File, label: 'article.form.icon', default: null, accept: 'image/*' },
    ];
  }

  public getData() {
    const data: any = { ...this.$refs.form.form };
    const filesToRemove: string[] = this.$refs.form.getFilesToRemove();

    if (data.assets === null) {
      delete data.assets;
    }
    if (data.banner === null) {
      delete data.banner;
    }
    if (data.icon === null) {
      delete data.icon;
    }

    if (this.initialValues) {
      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.assets &&
        // @ts-ignore
        this.initialValues.assets.thumb &&
        // @ts-ignore
        this.initialValues.assets.thumb.length > 0 &&
        filesToRemove.indexOf('assets') > -1
      ) {
        data.assets = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.icon &&
        // @ts-ignore
        this.initialValues.icon.thumb &&
        // @ts-ignore
        this.initialValues.icon.thumb.length > 0 &&
        filesToRemove.indexOf('icon') > -1
      ) {
        data.icon = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }

      // @ts-ignore
      if (
        // @ts-ignore
        this.initialValues.banner &&
        // @ts-ignore
        this.initialValues.banner.thumb &&
        // @ts-ignore
        this.initialValues.banner.thumb.length > 0 &&
        filesToRemove.indexOf('banner') > -1
      ) {
        data.banner = {
          thumb: '',
          medium: '',
          large: '',
          original: '',
        };
      }
    }

    return { ...data };
  }
}
