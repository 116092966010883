
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import VenueApiService from '@/api/http/VenueApiService';
import _ from 'lodash';

const api = new VenueApiService();

@Component({
  components: { VFormBuilder },
})
export default class HelloTessDialog extends mixins(StackedForm, Notification) {
  @Prop({ type: String, required: true }) public venueId!: string;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & {
      setData: (form: any) => void;
    };
  };
  public show: boolean = false;
  get items() {
    return [
      {
        name: 'hellotessApiBaseUrl',
        type: InputType.Text,
        label: 'article.helloTess.hellotessApiBaseUrl',
        rules: 'required',
      },
      {
        name: 'hellotessApiKey',
        type: InputType.Text,
        label: 'article.helloTess.hellotessApiKey',
        rules: 'required',
      },
    ];
  }

  public setShowModal(showModal: boolean = true) {
    this.show = showModal;
    if (!showModal) {
      this.$refs.form.reset();
      this.$refs.form.clearErrors();
    }
  }

  public async save() {
    if (await this.$refs.form.validate()) {
      this.$startLoading('import_foodcard');
      const data = _.cloneDeep(this.$refs.form.getData());
      await api.importFoodcardHelloTess(this.venueId, data);
      this.$stopLoading('import_foodcard');
      this.setShowModal(false);
      this.$emit('updateFoodcard');
      this.notifySuccess('article.helloTess.success');
    }
  }
}
