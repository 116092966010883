
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import GeneralForm from '@/components/article/form/GeneralForm.vue';
import ImageForm from '@/components/article/form/ImageForm.vue';
import MetaForm from '@/components/article/form/MetaForm.vue';
import OptionForm from '@/components/article/form/OptionForm.vue';
import AvailableHourForm from '@/components/article/form/AvailableHourForm.vue';
import Editable from '@/mixins/Editable';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import EditArticleLoader from '@/components/article/EditArticleLoader.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { Constrain } from '@/interfaces/models/Constrain';
import { Tag } from '@/interfaces/models/Tag';
import Filter from '@/interfaces/api/Filter';
import PriceByTypeForm from '@/components/article/form/PriceByTypeForm.vue';
import AvailabilityForm from '@/components/article/form/AvailabilityForm.vue';
import AllergensAndAdditivesForm from '@/components/article/form/AllergensAndAdditivesForm.vue';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import DisplayIdentifier from '../../mixins/DisplayIdentifier';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import OptionArticleTable from '@/components/option/article/OptionArticleTable.vue';
import VTable from '@/components/shared/table/VTable.vue';
import { Permission } from '@/enums/Permission';
import OptionArticleForm from '@/components/option/article/OptionArticleForm.vue';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { InputType } from '@/enums/InputType';
import OptionFormDependencies from '@/components/article/form/OptionFormDependencies.vue';

const foodcard = namespace('foodcard');
const venue = namespace('venue');
const tag = namespace('tag');
const constrain = namespace('constrain');
const printGroups = namespace('printGroups');

@Component({
  components: {
    AvailabilityForm,
    PriceByTypeForm,
    EditArticleLoader,
    AvailableHourForm,
    OptionForm,
    MetaForm,
    ImageForm,
    GeneralForm,
    VWrapper,
    AllergensAndAdditivesForm,
    VFormBuilder,
    OptionArticleTable,
    VTable,
    OptionArticleForm,
    VEmptyState,
    OptionFormDependencies,
  },
})
export default class EditOptionArticleModal extends mixins(
  Editable,
  StackedForm,
  Notification,
  EditArticleMixin,
  DisplayIdentifier,
) {
  @constrain.State('items') public constrains!: Constrain[];
  @constrain.Action('setFilter') public setConstrainFilter!: (f: Filter) => void;
  @constrain.Action('fetch') public getContrains!: any;

  @printGroups.State('items') public printGroups!: PrintGroups[];
  @printGroups.Action('setFilter') public setPrintGroupsFilter!: (f: Filter) => void;
  @printGroups.Action('fetch') public getPrintGroups!: any;

  @tag.State('items') public tags!: Tag[];
  @tag.Action('setFilter') public setTagFilter!: (f: Filter) => void;
  @tag.Action('fetch') public getTags!: any;

  @foodcard.State('categories') public categories!: Category[];

  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;

  @foodcard.Action('activateOptionArticle') public activateArticle!: any;
  @foodcard.Action('deactivateOptionArticle') public deactivateArticle!: any;
  @foodcard.Action('makeArticleOptionVisible') public visible!: any;
  @foodcard.Action('hideOptionArticle') public hide!: any;
  @foodcard.Action('deleteOptionArticle') public deleteOptionArticle!: any;
  @foodcard.Action('updateOptionArticle') public updateOptionArticle!: any;
  @foodcard.Action('storeOptionArticle') public storeOptionArticle!: any;
  @foodcard.Action('uploadOptionArticleImage') public uploadOptionArticleImage!: any;
  @foodcard.Action('setActiveOption') public setActive!: any;
  @foodcard.Action('showArticle') public showArticle!: any;

  @foodcard.Action('fetchArticleNames') public getArticleNames: any;
  @foodcard.State('articleNames') public articles!: Array<Partial<Article>>;

  @foodcard.Action('updateOption') public updateOption!: any;
  @foodcard.Action('destroyOption') public deleteOption!: any;

  @Prop({ type: String }) public articleId!: string;
  @Prop({ type: String }) public categoryId!: string;

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any; getFilesToRemove: () => string[] };
    optionArticleForm: InstanceType<typeof OptionArticleForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    optionSelectionForm: InstanceType<typeof OptionArticleForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    tagsSelectionForm: InstanceType<typeof OptionArticleForm> & {
      getData: () => any;
      validate: () => Promise<boolean | boolean[]>;
      reset: () => void;
    };
    FormDependencies: InstanceType<typeof OptionFormDependencies> & { getData: () => any };
  };

  public isLoading: boolean = this.$isLoading('venue_modal') || this.$isLoading('option_modal');
  public optionGroupToEdit: OptionGroup | null = null;
  public conectedOptions: any[] = [];

  public tableOptions: any[] = [];
  public inputValue: string = '';
  public selectedOptions: any = {};
  public activeOptionArticle: any = {};

  public addOptionDialog = false;
  public tagsOfArticleDialog = false;
  public tagsOfOptionsDialog = false;
  public displayIdentifiersDialog = false;

  public actionDialog: boolean = false;
  public optionModalVisible: boolean = false;

  public headers: any[] = [
    { text: 'name', align: 'start', value: 'name' },
    { text: 'Sort Order', value: 'sortOrder' },
    { text: 'Limit', value: 'limit' },
    { text: 'Required Amount', value: 'requiredAmount' },
    { text: 'Tags', value: 'tags', sortable: false },
    { text: 'Display Identifiers', value: 'displayIdentifiers', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false },
  ];

  public headersArticles = [
    {
      text: 'option.articles.table.name',
      value: 'name',
      default: true,
    },
    {
      text: 'article.table.price',
      value: 'price',
      type: 'decimal',
      default: true,
      editable: true,
    },
    {
      text: 'article.table.externalId',
      value: 'externalId',
      type: 'number',
      default: true,
      editable: true,
    },
    {
      text: 'article.table.tags',
      value: 'tags',
      type: 'slot',
    },
    {
      text: 'option.articles.table.active',
      value: 'isActive',
      type: 'slot',
    },
    {
      text: 'article.table.visible',
      value: 'visible',
      default: true,
      type: 'slot',
    },
  ];

  public snack: boolean = false;
  public snackColor = 'success';
  public snackText = 'Data saved';

  public async mounted() {
    this.$startLoading('venue_modal');
    this.$startLoading('option');
    this.$startLoading('category_modal');
    this.$startLoading('constrains');
    this.$startLoading('article');

    if (this.venue && this.venue._id) {
      this.setCategoryFilter({ venue: this.venue._id });
      this.setOptionFilter({ venue: this.venue._id });
      this.setConstrainFilter({ venue: this.venue._id });
      this.setTagFilter({ venue: this.venue._id });
      this.setPrintGroupsFilter({ venue: this.venue._id });
      await Promise.all([
        this.getCategories(),
        this.getArticleNames({ venue: this.venue._id }),
        this.getOptions(),
        this.getTags(),
        this.getContrains(),
        this.getPrintGroups(),
      ]);
    }
    await this.showArticle({ id: this.articleId, category: this.categoryId });
    if (this.editing) {
      await this.show({ id: this.articleId, category: this.categoryId });
    }
    this.$stopAllLoading();

    this.optionGroupToEdit = this.getGroups(this.article!.groups as string[])[0];
    this.conectedOptions = this.getGroups(this.article!.groups as string[]);
    this.copyAndLocalizedGroups();
  }

  get article() {
    if (this.active && this.editing) {
      return { ...this.active, category: this.categoryId };
    }

    return null;
  }

  get optionItems() {
    return this.options.map((o: OptionGroup) => {
      return {
        value: o._id,
        text: this.$options.filters!.localized(o.name),
      };
    });
  }

  get tagsItems() {
    return this.tags.map((t: Tag) => {
      return {
        value: t._id,
        text: this.$options.filters!.localized(t.name),
      };
    });
  }

  get itemsSelectionForm() {
    return [
      {
        name: 'groups',
        type: InputType.Autocomplete,
        label: 'article.form.options',
        items: this.optionItems,
        multiple: true,
      },
    ];
  }

  get itemsTagsForm() {
    return [
      {
        name: 'tags',
        type: InputType.Autocomplete,
        label: 'article.form.tags',
        items: this.tagsItems,
        multiple: true,
      },
    ];
  }

  get itemsDisplayIdentifiers() {
    return [
      {
        name: 'displayIdentifiers',
        type: InputType.Autocomplete,
        label: 'option.form.displayIdentifiers',
        items: this.displayIdentifiers,
        multiple: true,
      },
    ];
  }

  public async editedArticleModal(item: any) {
    const data: Partial<OptionArticle> = {
      group: this.selectedOptions._id,
      [`${item.field}`]: item.value,
    };
    this.$startLoading('updateOption');
    if (item) {
      await this.updateOptionArticle({ ...data, id: item.original._id });
    }
    this.$stopLoading('updateOption');
    this.activeOptionArticle = null;
    this.optionModalVisible = false;
  }

  public findTagsName(tag: string | Tag) {
    if (typeof tag === 'string') {
      const tags = this.tags.find((x: any) => x._id === tag);
      if (tags) {
        return this.$options.filters!.localized(tags.name);
      }
      return '';
    }
    if (typeof tag === 'object') {
      const tags = this.tags.find((x: any) => x._id === (tag as Tag)._id);
      if (tags) {
        return this.$options.filters!.localized(tags.name);
      }
      return '';
    }
  }

  public getGroup(id: string): OptionGroup | undefined {
    return this.options.find((og: OptionGroup) => og._id === id);
  }

  public getGroups(optionGroups: string[] = []) {
    const groups = [];
    for (const og of optionGroups) {
      const a = this.getGroup(og);
      if (a) {
        groups.push(a);
      }
    }

    return groups;
  }

  public copyAndLocalizedGroups() {
    const to = this.conectedOptions.map((op) => {
      const name = this.$options.filters!.localized(op.name);

      return { ...op, name };
    });

    this.tableOptions = to;
  }

  public mainCancel() {
    this.$emit('cancel');
  }

  public async saveSnack(id: string, fieldValue: string, fieldName: string) {
    const value = { [fieldName]: fieldValue };
    const data: Partial<OptionGroup> = { venue: this.venue._id, ...value };

    // this.$startLoading('option');
    if (this.editing) {
      await this.updateOption({ id, ...data });
    } else {
      await this.store(data);
    }
    // this.$stopLoading('option');

    this.conectedOptions = this.getGroups(this.article!.groups as string[]);
    this.copyAndLocalizedGroups();

    this.snack = true;
    this.snackColor = 'success';
    this.snackText = 'Data saved';
  }

  public cancelSnack() {
    this.snack = true;
    this.snackColor = 'error';
    this.snackText = 'Canceled';
  }

  public openActionDialog(item: any) {
    this.setActive(item);
    this.selectedOptions = item;
    this.showArticle({ id: this.articleId, category: this.categoryId });
    this.actionDialog = true;
  }

  public cancelActionDialog() {
    this.actionDialog = false;
  }

  public cancelOptionModalVisible() {
    this.optionModalVisible = false;
  }

  public saveOptionArticle() {
    this.$refs.optionArticleForm.validate().then(async (res: boolean[] | boolean) => {
      if (this.isValid(res)) {
        let reset: boolean = false;
        const data: Partial<OptionArticle> = {
          group: this.selectedOptions._id,
          ...this.$refs.optionArticleForm.getData(),
        };
        this.$startLoading('updateOption');
        if (this.activeOptionArticle) {
          await this.updateOptionArticle({ ...data, id: this.activeOptionArticle._id });
        } else {
          reset = true;
          this.activeOptionArticle = await this.storeOptionArticle(data);
        }

        if (data.assets && data.assets instanceof File) {
          const formData: FormData = new FormData();
          formData.append('asset', data.assets);
          await this.uploadOptionArticleImage({
            group: this.selectedOptions._id,
            id: this.activeOptionArticle!._id,
            image: formData,
          });
        }

        if (reset) {
          this.$refs.optionArticleForm.reset();
        }

        this.$stopLoading('updateOption');
        this.activeOptionArticle = null;
        this.optionModalVisible = false;
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  public async saveTagsArticle() {
    const data: Partial<OptionArticle> = {
      group: this.selectedOptions._id,
      ...this.$refs.tagsSelectionForm.getData(),
    };
    this.$startLoading('updateOption');
    if (this.activeOptionArticle) {
      await this.updateOptionArticle({ ...data, id: this.activeOptionArticle._id });
    } else {
      this.activeOptionArticle = await this.storeOptionArticle(data);
    }

    this.$stopLoading('updateOption');
    this.activeOptionArticle = null;
    this.tagsOfArticleDialog = false;
  }

  public async saveTagsOptions() {
    const data: Partial<OptionGroup> = {
      group: this.selectedOptions._id,
      ...this.$refs.tagsSelectionForm.getData(),
    };
    this.$startLoading('updateOption');
    if (this.activeOptionArticle) {
      await this.updateOption({ id: this.selectedOptions._id, ...data });
    } else {
      this.activeOptionArticle = await this.store(data);
    }

    this.$stopLoading('updateOption');

    this.conectedOptions = this.getGroups(this.article!.groups as string[]);
    this.copyAndLocalizedGroups();
    this.tagsOfOptionsDialog = false;
    this.displayIdentifiersDialog = false;
  }

  public async saveOptionSelection() {
    await this.$refs.optionSelectionForm.validate().then(async (res: boolean[] | boolean) => {
      if (res) {
        const reset: boolean = false;
        const data: Partial<Article> = {
          ...this.$refs.optionSelectionForm.getData(),
          category: this.categoryId,
        };
        if (this.editing) {
          data.id = this.active._id;
          await this.update(data);
        } else {
          await this.store(data);
        }

        this.$stopLoading('updateArticle');
        this.addOptionDialog = false;
      } else {
        this.notifyError('notification.form');
      }
    });

    await this.showArticle({ id: this.articleId, category: this.categoryId });
    this.conectedOptions = this.getGroups(this.active!.groups as string[]);
    this.copyAndLocalizedGroups();
  }

  public async saveDependencies() {
    await this.$refs.FormDependencies.validate().then(async (res: boolean[] | boolean) => {
      if (res) {
        const reset: boolean = false;
        const data: Partial<Article> = {
          ...this.$refs.FormDependencies.getData(),
          category: this.categoryId,
        };
        if (this.editing) {
          data.id = this.active._id;
          await this.update(data);
        } else {
          await this.store(data);
        }

        this.$stopLoading('updateArticle');
        this.addOptionDialog = false;
      } else {
        this.notifyError('notification.form');
      }
    });

    await this.showArticle({ id: this.articleId, category: this.categoryId });
    this.conectedOptions = this.getGroups(this.active!.groups as string[]);
    this.copyAndLocalizedGroups();
  }

  public canToggleActivation(val: boolean) {
    if (val) {
      return this.$can(Permission.OPTION_ARTICLE_DEACTIVATE);
    }
    return this.$can(Permission.OPTION_ARTICLE_ACTIVATE);
  }

  public canToggleVisibility(val: boolean) {
    if (val) {
      return this.$can(Permission.ARTICLE_VISIBLE);
    }
    return this.$can(Permission.ARTICLE_HIDE);
  }

  public async destroyArticleOfOption(art: Article) {
    this.$startLoading('article');
    await this.deleteOptionArticle(art);
    this.$stopLoading('article');
  }

  public async destroyOptionGroup(opt: OptionGroup) {
    const filteredGroups = (this.active.groups as string[]).filter((group: string) => group !== opt._id);
    await this.$refs.optionSelectionForm.validate().then(async (res: boolean[] | boolean) => {
      if (res) {
        const reset: boolean = false;
        const data: Partial<Article> = {
          groups: filteredGroups,
          category: this.categoryId,
        };
        if (this.editing) {
          data.id = this.active._id;
          await this.update(data);
        } else {
          await this.store(data);
        }

        this.$stopLoading('updateArticle');
        this.addOptionDialog = false;
      } else {
        this.notifyError('notification.form');
      }
    });
    await this.showArticle({ id: this.articleId, category: this.categoryId });
    this.conectedOptions = this.getGroups(this.active!.groups as string[]);
    this.copyAndLocalizedGroups();
  }

  public async toggleActivation({ item, value }: { item: Article; value: boolean }) {
    this.$startLoading('article');
    let data = null;
    if (value) {
      data = await this.deactivateArticle({ venue: this.venue._id, articles: [item._id] });
    } else {
      data = await this.activateArticle({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('article');
  }

  public async toggleVisibility({ item, value }: { item: Article; value: boolean }) {
    this.$startLoading('article');
    let data = null;
    if (value) {
      data = await this.hide({ venue: this.venue._id, articles: [item._id] });
    } else {
      data = await this.visible({ venue: this.venue._id, articles: [item._id] });
    }
    this.$stopLoading('article');
  }
}
