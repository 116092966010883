
import { Component, Prop } from 'vue-property-decorator';
import { InputType } from '@/enums/InputType';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { Venue } from '@/interfaces/models/Venue';
import { namespace } from 'vuex-class';
import _ from 'lodash';
import Notification from '@/mixins/Notification';

const api = new FoodcardApiService();
const app = namespace('app');

@Component({
  components: { VFormBuilder },
})
export default class CopyArticlesDialog extends mixins(StackedForm, Notification) {
  @Prop({ type: Array, required: true }) public categories!: Category[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & {
      setData: (form: any) => void;
    };
  };

  public articles: Article[] = [];
  public targetCategories: Category[] = [];
  public show: boolean = false;
  public sourceCategorySelected: boolean = false;
  public targetVenueSelected: boolean = false;

  @app.State('venues') protected venues!: Venue[];

  get items() {
    return [
      {
        name: 'sourceCategory',
        type: InputType.Select,
        label: 'article.copyDialog.sourceCategory',
        rules: 'required',
        items: this.cats,
        change: this.onSourceCategoryChange,
      },
      {
        name: 'venue',
        type: InputType.Autocomplete,
        label: 'article.copyDialog.venue',
        rules: 'required',
        items: this.venuesReadable,
        change: this.onVenueChange,
        disabled: !this.sourceCategorySelected,
      },
      {
        name: 'targetCategory',
        type: InputType.Select,
        label: 'article.copyDialog.targetCategory',
        rules: 'required',
        items: this.targetCats,
        disabled: !this.sourceCategorySelected || !this.targetVenueSelected,
      },
      {
        name: 'articles',
        type: InputType.Autocomplete,
        label: 'article.copyDialog.articles',
        rules: 'required',
        useChips: true,
        multiple: true,
        items: this.articleItems,
        disabled: !this.sourceCategorySelected || !this.targetVenueSelected,
      },
    ];
  }
  get cats() {
    return this.categories.map((cat: Category) => {
      return {
        value: cat._id,
        text: this.$options.filters!.localized(cat.name),
      };
    });
  }

  get targetCats() {
    return this.targetCategories.map((cat: Category) => {
      return {
        value: cat._id,
        text: this.$options.filters!.localized(cat.name),
      };
    });
  }

  get articleItems() {
    return this.articles.map((o: Partial<Article>) => {
      return {
        value: o._id,
        text: this.$options.filters!.localized(o.name),
      };
    });
  }

  get venuesReadable() {
    return this.venues.map((o: Partial<Venue>) => {
      return {
        value: o._id,
        text: this.$options.filters!.localized(o.name),
      };
    });
  }

  public async onSourceCategoryChange(category: string) {
    this.sourceCategorySelected = false;
    this.$refs.form.setData({ sourceCategory: category, venue: '', targetCategory: '', articles: [] });
    this.$refs.form.clearErrors();
    this.targetVenueSelected = false;
    await this.fetchArticles(category);
    this.sourceCategorySelected = true;
  }

  public async onVenueChange(venue: string) {
    this.targetVenueSelected = false;
    await this.fetchCategories(venue);
    this.targetVenueSelected = true;
  }

  public async fetchArticles(category: string) {
    const { data } = await api.getArticles(null, { category });
    // @ts-ignore
    this.articles = data;
  }

  public async fetchCategories(venue: string) {
    const { data } = await api.getCategories(null, { venue });
    // @ts-ignore
    this.targetCategories = data;
  }

  public setShowModal(showModal: boolean = true) {
    this.show = showModal;
    if (!showModal) {
      this.$refs.form.reset();
      this.$refs.form.clearErrors();
      this.sourceCategorySelected = false;
      this.targetVenueSelected = false;
    }
  }

  public async save() {
    if (this.$refs.form.validate()) {
      this.$startLoading('copy_article');
      const data = _.cloneDeep(this.$refs.form.getData());
      delete data.venue;
      await api.copyArticles(data).catch(() => this.$stopLoading('copy_article'));
      this.$stopLoading('copy_article');
      this.setShowModal(false);
      this.notifySuccess('article.copyDialog.success');
    }
  }
}
