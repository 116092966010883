
import { Component, Prop, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import GeneralForm from '@/components/article/form/GeneralForm.vue';
import ImageForm from '@/components/article/form/ImageForm.vue';
import MetaForm from '@/components/article/form/MetaForm.vue';
import OptionForm from '@/components/article/form/OptionForm.vue';
import AvailableHourForm from '@/components/article/form/AvailableHourForm.vue';
import Editable from '@/mixins/Editable';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import EditArticleLoader from '@/components/article/EditArticleLoader.vue';
import StackedForm from '@/mixins/StackedForm';
import Notification from '@/mixins/Notification';
import { Constrain } from '@/interfaces/models/Constrain';
import { Tag } from '@/interfaces/models/Tag';
import Filter from '@/interfaces/api/Filter';
import PriceByTypeForm from '@/components/article/form/PriceByTypeForm.vue';
import AvailabilityForm from '@/components/article/form/AvailabilityForm.vue';
import AllergensAndAdditivesForm from '@/components/article/form/AllergensAndAdditivesForm.vue';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import NutritionForm from '@/components/article/form/NutritionForm.vue';
import FormatPriceInObject from '@/mixins/FormatPriceInObject';
import LoyaltyOption from '@/components/article/form/LoyaltyOption.vue';
import EditLocalization from '@/mixins/EditLocalization';
import StyleForm from '@/components/article/form/StyleForm.vue';
import WoltArticleOptionsForm from '@/components/article/form/WoltArticleOptionsForm.vue';
import UberEatsArticleOptionsForm from '@/components/article/form/UberEatsArticleOptionsForm.vue';
import JetArticleOptionsForm from '@/components/article/form/JetArticleOptionsForm.vue';
import VisibilityForm from '@/components/article/form/VisibilityForm.vue';
import { isCustomerGroup } from '@/util/helper';
import { CustomerGroup } from '@/enums/CustomerGroup';

const foodcard = namespace('foodcard');
const tag = namespace('tag');
const constrain = namespace('constrain');
const printGroups = namespace('printGroups');
const company = namespace('company');

@Component({
  components: {
    JetArticleOptionsForm,
    UberEatsArticleOptionsForm,
    WoltArticleOptionsForm,
    LoyaltyOption,
    NutritionForm,
    AvailabilityForm,
    PriceByTypeForm,
    EditArticleLoader,
    AvailableHourForm,
    OptionForm,
    MetaForm,
    ImageForm,
    GeneralForm,
    VWrapper,
    AllergensAndAdditivesForm,
    StyleForm,
    VisibilityForm,
  },
})
export default class EditArticleModal extends mixins(
  Editable,
  StackedForm,
  Notification,
  EditArticleMixin,
  FormatPriceInObject,
  EditLocalization,
) {
  @constrain.State('items') public constrains!: Constrain[];
  @constrain.Action('setFilter') public setConstrainFilter!: (f: Filter) => void;
  @constrain.Action('fetch') public getContrains!: any;
  @printGroups.State('items') public printGroups!: PrintGroups[];
  @printGroups.Action('setFilter') public setPrintGroupsFilter!: (f: Filter) => void;
  @printGroups.Action('fetch') public getPrintGroups!: any;
  @tag.State('items') public tags!: Tag[];
  @tag.Action('setFilter') public setTagFilter!: (f: Filter) => void;
  @tag.Action('fetch') public getTags!: any;

  @foodcard.State('categories') public categories!: Category[];

  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;

  @foodcard.Action('fetchArticleNames') public getArticleNames: any;
  @foodcard.Action('fetchOptionByArticleId') public getOptionByArticleId: any;
  @foodcard.State('articleNames') public articles!: Array<Partial<Article>>;

  @foodcard.Action('concatPaginatedOptions') public getConcatPaginatedOptions: any;
  @foodcard.Action('setOptionsByArticle') public setOptionsByArticle: any;
  @foodcard.Action('resetOptions') public resetOptions: any;
  @foodcard.State('optionFilter') public optionFilter!: Filter;
  @company.Action('getCompaniesByVenue') public getCompaniesByVenue!: any;

  @Prop({ type: String }) public articleId!: string;
  @Prop({ type: String }) public categoryId!: string;

  public $refs!: {
    general: InstanceType<typeof GeneralForm> & { getData: () => any };
    image: InstanceType<typeof ImageForm> & { getData: () => any };
    meta: InstanceType<typeof MetaForm> & { getData: () => any };
    option: InstanceType<typeof OptionForm> & { getData: () => any };
    price: InstanceType<typeof PriceByTypeForm> & { getData: () => any };
    displayPrice: InstanceType<typeof PriceByTypeForm> & { getData: () => any };
    availableHours: InstanceType<typeof AvailableHourForm> & { getData: () => any };
    availability: InstanceType<typeof AvailabilityForm> & { getData: () => any };
    compound: InstanceType<typeof AllergensAndAdditivesForm> & { getData: () => any };
    nutritions: InstanceType<typeof NutritionForm> & { getData: () => any };
    loyalty: InstanceType<typeof LoyaltyOption> & { getData: () => any };
    style: InstanceType<typeof StyleForm> & { getData: () => any };
    wolt: InstanceType<typeof WoltArticleOptionsForm> & { getData: () => any };
    jet: InstanceType<typeof JetArticleOptionsForm> & { getData: () => any };
    uberEats: InstanceType<typeof UberEatsArticleOptionsForm> & { getData: () => any };
    visibility: InstanceType<typeof VisibilityForm> & { getData: () => any };
  };

  get article() {
    if (this.active && this.editing) {
      return { ...this.active, category: this.categoryId };
    }

    return null;
  }

  public isLoading: boolean = this.$isLoading('venue_modal') || this.$isLoading('option_modal');

  public async mounted() {
    this.$startLoading('venue_modal');
    this.$startLoading('category_modal');
    this.$startLoading('article');

    if (this.venue && this.venue._id) {
      this.setCategoryFilter({ venue: this.venue._id });

      await Promise.all([this.getCategories(), this.getArticleNames({ venue: this.venue._id })]).catch((e: any) => {
        this.cancel();
      });
    }

    if (this.editing) {
      await this.show({ id: this.articleId, category: this.categoryId });
    }
    this.$stopAllLoading();
  }

  public async fetchMetaData() {
    if (this.venue && this.venue._id) {
      try {
        this.$startLoading('save_article');
        this.setTagFilter({ venue: this.venue._id });
        this.setConstrainFilter({ venue: this.venue._id });
        this.setPrintGroupsFilter({ venue: this.venue._id });
        await Promise.all([this.getPrintGroups(), this.getTags(), this.getContrains()]).catch((e: any) => {
          this.cancel();
        });
      } finally {
        this.$stopLoading('save_article');
      }
    }
  }

  public async fetchOptionData() {
    if (this.venue && this.venue._id) {
      this.setOptionFilter({ venue: this.venue._id, limit: 20 });
      try {
        this.$startLoading('save_article');
        await Promise.all([
          this.getOptionByArticleId({ articleId: this.articleId, categoryId: this.categoryId }),
        ]).catch((e: any) => {
          this.cancel();
        });
      } finally {
        this.$stopLoading('save_article');
      }
    }
  }

  public async fetchCompaniesData() {
    if (this.venue && this.venue._id && isCustomerGroup(CustomerGroup.DeanAndDavid)) {
      try {
        this.$startLoading('save_article');
        await this.getCompaniesByVenue({ id: this.venue._id });
      } finally {
        this.$stopLoading('save_article');
      }
    }
  }

  public selectOptionTab() {
    this.fetchOptionData();
  }

  public selectMetaTab() {
    this.fetchMetaData();
  }

  public selectPriceTab() {
    this.fetchCompaniesData();
  }

  public selectVisibilityTab() {
    this.fetchCompaniesData();
  }

  public onSearch(filter: { page: number; limit: number; search: string }) {
    this.setOptionFilter({ ...this.optionFilter, ...{ search: filter.search, limit: filter.limit } });
    // delete all options that they are concatenated (avoid duplicates)
    this.resetOptions();
    this.setOptionsByArticle();
    this.getConcatPaginatedOptions(filter.page);
  }

  get isDeanAndDavidGroup() {
    return isCustomerGroup(CustomerGroup.DeanAndDavid);
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue) {
      this.$startLoading('category');
      this.setCategoryFilter({ venue: this.venue._id });
      this.setOptionFilter({ venue: this.venue._id, limit: 20 });
      await this.getCategories();
      this.getOptionByArticleId({ articleId: this.articleId, categoryId: this.categoryId });
      this.$stopAllLoading();
    }
  }
  protected async save() {
    this.$startLoading('save_article');
    Promise.all(await this.validate()).then(async (res: any) => {
      if (this.isValid(res)) {
        const data: any = this.getMergedData();
        // TODO - may mutate unexpected fields
        const arr = ['price', 'displayPrice', 'standard', 'inside', 'takeAway', 'delivery'];
        arr.map((type: any) => {
          // mutate merged data
          const res = this.deepSearchItems(data, type, (k: any, v: any) => {
            if (v && v.includes) {
              return v.includes(',');
            }
          });
          res.map((obj: any) => {
            obj[type] = obj[type].replaceAll(',', '.');
          });
        });

        if (this.editing) {
          if (data && this.venue?.customerGroup === 'the_ash' && data?.piggyRewardUuid === undefined) {
            data.piggyRewardUuid = '';
          }

          if (this.active && this.active.name) {
            data.name = this.serializeLocalization(this.active.name, data.name);
          }

          data.id = this.active._id;
          if (data.category !== this.categoryId) {
            await this.newCategory({
              category: this.categoryId,
              id: this.active._id,
              newId: data.category,
            });
          }
          await this.update(data);
        } else {
          await this.store(data);
        }
        let formData: FormData;
        if (data.assets && data.assets instanceof File) {
          formData = new FormData();
          formData.append('asset', data.assets);
          await this.uploadImage({ ...data, image: formData });
        }
        if (data.banner && data.banner instanceof File) {
          formData = new FormData();
          formData.append('asset', data.banner);
          await this.uploadBanner({ ...data, image: formData });
        }
        if (data.icon && data.icon instanceof File) {
          formData = new FormData();
          formData.append('asset', data.icon);
          await this.uploadIcon({ ...data, image: formData });
        }
        this.$emit('cancel', { updateList: data.category !== this.categoryId });
        this.$stopLoading('save_article');
      } else {
        this.notifyError('notification.form');
        this.$stopLoading('save_article');
      }
    });
  }

  protected cancel() {
    this.$emit('cancel');
  }

  protected getMergedData() {
    const woltSettings = this.$refs?.wolt?.getData();
    if (woltSettings?.price === '') {
      delete woltSettings['price'];
    }

    const jetSettings = this.$refs?.jet?.getData();
    if (jetSettings?.price === '') {
      delete jetSettings['price'];
    }

    const uberEatsSettings = this.$refs?.uberEats?.getData();
    if (uberEatsSettings?.price === '') {
      delete uberEatsSettings['price'];
    }

    const externalOrderProviderSettings = [woltSettings, jetSettings, uberEatsSettings].filter(Boolean);

    return {
      ...this.$refs.general.getData(),
      ...this.$refs.image.getData(),
      ...this.$refs.style.getData(),
      ...this.$refs.meta.getData(),
      ...this.$refs.option.getData(),
      ...this.$refs.availableHours.getData(),
      ...this.$refs.nutritions.getData(),
      visibilites: [...(this.$refs.visibility?.getData() || [])],
      priceByType: {
        ...this.$refs.price.getData(),
      },
      priceLockByType: {
        ...this.$refs.price.getDataPriceLockByType(),
      },
      prices: [...this.$refs.price.getPrices()],
      displayPriceByType: {
        ...this.$refs.displayPrice.getData(),
      },
      availability: {
        ...this.$refs.availability.getData(),
      },
      compound: {
        ...this.$refs.compound.getData(),
      },
      externalOrderProviderSettings,
      ...this.$refs.loyalty.getData().loyalty,
    };
  }
}
