
import { Component, Prop } from 'vue-property-decorator';
import StackedForm from '../../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import { Tag } from '@/interfaces/models/Tag';
import { Constrain } from '@/interfaces/models/Constrain';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import { replaceCommaWithDot } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class MetaForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public tags!: Tag[];
  @Prop({ type: Array, required: true }) public constrains!: Constrain[];
  @Prop({ type: Array, required: true }) public printGroups!: PrintGroups[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { getData: () => any };
    measurement: InstanceType<typeof VFormBuilder> & { getData: () => any };
    manufacturer: InstanceType<typeof VFormBuilder> & { getData: () => any };
  };

  get items() {
    return [
      { name: 'externalId', type: InputType.Text, label: 'article.form.externalId' },
      { name: 'internalNote', type: InputType.Text, label: 'article.form.internalNote' },
      { name: 'preparationTime', type: InputType.Text, label: 'article.form.preparationTime' },
      { name: 'gtin', type: InputType.Text, label: 'article.form.gtin' },
      { name: 'plu', type: InputType.Text, label: 'article.form.plu' },
      { name: 'stockAmount', type: InputType.Text, label: 'article.form.stock', rules: 'integer', default: 0 },
      { name: 'sortOrder', type: InputType.Text, label: 'article.form.sort', rules: 'integer', default: 1 },
      {
        name: 'printerSortOrder',
        type: InputType.Text,
        label: 'article.form.printerSort',
        rules: 'integer',
        default: 1,
      },
      { name: 'isNoticeable', type: InputType.Checkbox, label: 'article.form.isNoticeable', default: true },
      { name: 'isControlArticle', type: InputType.Checkbox, label: 'article.form.isControlArticle', default: false },
      { name: 'takeaway', type: InputType.Checkbox, label: 'article.form.takeaway', default: true },
      { name: 'isPrintable', type: InputType.Checkbox, label: 'article.form.isPrintable', default: true },
      { name: 'isRateable', type: InputType.Checkbox, label: 'article.form.isRateable', default: true },
      { name: 'info', type: InputType.Language, useRichText: true, label: 'article.form.info' },
      {
        name: 'deposit',
        type: InputType.Select,
        label: 'article.form.deposit',
        items: this.depositTypes,
        default: '0.00',
      },
      { name: 'kcal', type: InputType.Text, label: 'article.form.kcal', rules: 'integer', default: 0 },
      { name: 'fsk', type: InputType.Select, label: 'article.form.fsk', items: this.fskItems, default: 0 },
      { name: 'alcoholPercentage', type: InputType.Decimal, label: 'article.form.alcoholPercentage' },
      { name: 'caffeineMg', type: InputType.Decimal, label: 'article.form.caffeineMg', rules: 'integer' },
      { name: 'tags', type: InputType.Select, label: 'article.form.tags', items: this.tagItems, multiple: true },
      {
        name: 'constrains',
        type: InputType.Select,
        label: 'article.form.constrains',
        items: this.constrainItems,
        multiple: true,
      },
      {
        name: 'printGroups',
        type: InputType.Select,
        label: 'article.form.printGroups',
        items: this.printGroupsItems,
        multiple: true,
      },
    ];
  }

  get measurementItems() {
    return [
      { name: 'unit', label: 'article.form.unit', type: InputType.Select, items: this.units, default: null },
      {
        name: 'amount',
        label: 'article.form.amount',
        type: InputType.Text,
        default: null,
        rules: `numbers_with_comma_or_dot:${this.$t('article.form.amount')}`,
      },
    ];
  }

  get manufacturerItems() {
    return [
      { name: 'name', type: InputType.Text, label: 'article.form.name' },
      {
        name: 'address',
        type: InputType.Text,
        label: 'article.form.address',
      },
      {
        name: 'country',
        type: InputType.Text,
        label: 'article.form.country',
      },
    ];
  }

  get units() {
    return [
      { text: 'G', value: 'g' },
      { text: 'KG', value: 'kg' },
      { text: 'ML', value: 'ml' },
      { text: 'L', value: 'l' },
    ];
  }

  get tagItems() {
    return this.tags.map((t: Tag) => {
      return {
        value: t._id,
        text: this.$options.filters!.localized(t.name),
      };
    });
  }

  get constrainItems() {
    return this.constrains.map((c: Constrain) => {
      return {
        value: c._id,
        text: this.$options.filters!.localized(c.name),
      };
    });
  }

  get printGroupsItems() {
    return this.printGroups.map((c: PrintGroups) => {
      return {
        value: c._id,
        text: this.$options.filters!.localized(c.name),
      };
    });
  }

  get depositTypes() {
    return ['0.00', '0.08', '0.15', '0.25'];
  }

  get fskItems() {
    return [0, 16, 18, 20];
  }

  public serializeMeasurement(measurement: InstanceType<typeof VFormBuilder>) {
    const data = { ...measurement.form };
    if (data?.hasOwnProperty('amount')) {
      data.amount = replaceCommaWithDot(data.amount);
    }

    return data;
  }

  public getData() {
    return {
      ...this.$refs.form.form,
      measurement: { ...this.serializeMeasurement(this.$refs?.measurement) },
      manufacturer: { ...this.$refs.manufacturer?.form },
    };
  }
}
