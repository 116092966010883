
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import { InputType } from '@/enums/InputType';
import { Article } from '@/interfaces/models/Article';
import { PiggyReward } from '@/interfaces/models/PiggyReward';
import { CustomerGroup } from '@/enums/CustomerGroup';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';

const reward = namespace('reward');

@Component({
  components: { VFormBuilder },
})
export default class LoyaltyOption extends mixins(StackedForm) {
  @Prop({ type: Object, required: true }) public venue!: Venue;
  @Prop({ type: String, default: '' }) public customerGroup!: CustomerGroup;

  @reward.Action('fetch') public getRewards!: any;
  @reward.Action('resetRewards') public resetRewards!: any;
  @reward.Action('setFilter') public setRewardsFilter!: any;
  @reward.State('items') public rewards!: PiggyReward[];

  public $refs!: {
    loyalty: InstanceType<typeof VFormBuilder> & { getData: () => any };
  };

  public async mounted() {
    try {
      if (this.venue && this.venue._id && this.venue.piggyLoyaltyEnabled) {
        this.setRewardsFilter({ venue: this.venue._id });
        await this.getRewards();
      } else {
        await this.resetRewards();
      }
    } catch (e) {
      await this.resetRewards();
    }
  }

  get filteredRewards() {
    return this.rewards.map((reward: PiggyReward) => {
      reward.disable = false;
      return reward;
    });
  }

  get initValues(): any {
    const article: Article = this.initialValues as Article;
    const initObject: any = {};

    if (article && article.isPiggyLoyalty) {
      initObject.isPiggyLoyalty = article.isPiggyLoyalty;
    }

    if (article && article.piggyPointsNeeded) {
      initObject.piggyPointsNeeded = article.piggyPointsNeeded;
    }

    if (article && article.piggyRewardUuid) {
      initObject.piggyRewardUuid = article.piggyRewardUuid;
    }

    if (article && article.redeemableAtCheckoutOnly) {
      initObject.redeemableAtCheckoutOnly = article.redeemableAtCheckoutOnly;
    }

    return initObject;
  }

  get loyaltyItem() {
    return [
      {
        name: 'isPiggyLoyalty',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.isPiggyLoyalty',
      },
      {
        name: 'redeemableAtCheckoutOnly',
        type: InputType.Checkbox,
        default: false,
        label: 'article.form.redeemableAtCheckoutOnly',
      },
      {
        name: 'piggyPointsNeeded',
        type: InputType.Text,
        label: 'article.form.piggyPointsNeeded',
      },
      {
        name: 'piggyRewardUuid',
        type: InputType.CustomSelect,
        label: 'article.form.piggyRewardUuid',
        items: this.filteredRewards,
        itemText: 'title',
        itemValue: 'uuid',
        clearable: true,
      },
    ];
  }

  public getData() {
    return {
      loyalty: { ...this.$refs.loyalty.form },
    };
  }
}
